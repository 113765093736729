(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

$("#section-aqwryuhnjc form").submit(function(e){
    validateStringField(e, $("#name"));
    validateEmail(e, $("#email"));   
    validateRequiredField(e, $("#text"));
    validateRequiredField(e, $("#attached_file")); 
    validateCaptcha(e);
});

$("#section-yxa1yl0311saay7 form").submit(function(e){    
    validateStringField(e, $("#fullname"));
    validateStringField(e, $("#lastname"));
    validateEmail(e, $("#emailaddress"));
    validateRequiredField(e, $("#how_can_we_help"));
    validateCaptcha(e);
    validateRequiredField(e, $("#country"));
})

function validateRequiredField(e, elm){
    if(elm.length === 0){
        return false;
    }
    else if(!elm.val()){
        e.preventDefault();
        elm.prev().text("This field is required.")
    }
    else{
        elm.prev().text("")
    }
}

function validateStringField(e, elm){
    if(elm.length === 0){
        return false;
    }
    else if(!elm.val().trim()){
        e.preventDefault();
        elm.prev().text("This field is required.")
    }
    else if(isInvalidString(elm.val().trim())){
        e.preventDefault();
        elm.prev().text("Please avoid using special characters.")
    }
    else{
        elm.prev().text("")
    }
}

function validateCaptcha(e){
    var response = grecaptcha.getResponse();
    
    if(response.length == 0){
        e.preventDefault();
        $("#recaptchav2 .error:last").text("Please select the checkbox in the ReCaptcha image.");
    }
    else{
        $("#recaptchav2 .error:last").text("");
    }
}

function validateEmail(e, elm){
    if(elm.length === 0){
        return false;
    }
    else if(!elm.val().trim()){
        e.preventDefault();
        elm.prev().text("This field is required.")
    }
    else if(!isEmail(elm.val().trim())){
        e.preventDefault();
        elm.prev().text("Please enter a valid email address.")
    }
    else{
        elm.prev().text("")
    }
}

function validatePhone(e, elm){
    if(elm.length === 0){
        return false;
    }
    else if(!elm.val().trim()){
        e.preventDefault();
        elm.prev().text("This field is required.")
    }
    else if(!isNumber(elm.val().trim())){
        e.preventDefault();
        elm.prev().text("This field must be a valid number.")
    }
    else{
        elm.prev().text("")
    }
}

function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

function isNumber(string) {
  return string.match(/^[0-9]+$/) != null;
}

function isInvalidString(string) {
  return string.match(/[0-9!@#$%^&*()]+/) != null;
}


},{}],2:[function(require,module,exports){
'use strict';

$('[data-toggle="google-map"]').each(function () {
	var $that = $(this);
	var markers = $that.find('.map-marker').clone();
   var map = new google.maps.Map(this, $that.data());

   markers.each(function () {
    	var $that = $(this);
    	var options = $.extend({map: map}, $that.data());
       	new google.maps.Marker(options);
    });

    $(window).on('resize', function () {
    	google.maps.event.trigger(map, 'resize');
    });
});
},{}],3:[function(require,module,exports){
'use strict';

$('[data-toggle="magnific-popup"]').each(function () {
	var $that = $(this);	
	$that.magnificPopup($that.data());

});

$('.mfp-video-action-watch').magnificPopup({
     type: 'inline',
     callbacks: {
         open: function() {
             // https://github.com/dimsemenov/Magnific-Popup/issues/125
             $('html').css('margin-right', 0);
             $(this.content).find('video')[0].play();
         },
         close: function() {
             $(this.content).find('video')[0].load();
         }
     }
 });
},{}],4:[function(require,module,exports){
;(function () {
	
	'use strict';

// Disable right-click
var holdClick = function() {
	document.onmousedown=disableclick;
	status="Right Click Disabled";
	function disableclick(event)
	{
		if(event.button==2)
		{
			alert(status);
			return false;    
		}
	}
}

	// iPad and iPod detection	
	var isiPad = function(){
		return (navigator.platform.indexOf("iPad") != -1);
	};

	var isiPhone = function(){
		return (
			(navigator.platform.indexOf("iPhone") != -1) || 
			(navigator.platform.indexOf("iPod") != -1)
			);
	};

	// Parallax
	var parallax = function() {
		$(window).stellar();
	};

	// Navigation Scripts to Show Header on Scroll-Up
	var navScrollUp = function() {
		jQuery(document).ready(function($) {
			var MQL = 1170;

    //primary navigation slide-in effect
    if ($(window).width() > MQL) {
    	var headerHeight = $('.navbar-custom').height();
    	$(window).on('scroll', {
    		previousTop: 0
    	},
    	function() {
    		var currentTop = $(window).scrollTop();
                //check if user is scrolling up
                if (currentTop < this.previousTop) {
                    //if scrolling up...
                    if (currentTop > 0 && $('.navbar-custom').hasClass('is-fixed')) {
                    	$('.navbar-custom').addClass('is-visible');
                    } else {
                    	$('.navbar-custom').removeClass('is-visible is-fixed');
                    }
                  } else {
                    //if scrolling down...
                    $('.navbar-custom').removeClass('is-visible');
                    if (currentTop > headerHeight && !$('.navbar-custom').hasClass('is-fixed')) $('.navbar-custom').addClass('is-fixed');
                  }
                  this.previousTop = currentTop;
                });
    }
  });

}



var lightboxShow = function() {
	var $thumbnail = $('#lightgallery');

	$(document).ready(function() {

		$thumbnail.justifiedGallery({
			rowHeight : 200,
			lastRow : 'nojustify',
			margins : 10
		});

		$thumbnail.lightGallery();			

	});   
};



	// Burger Menu
	var burgerMenu = function() {

		$('body').on('click', '.js-nav-toggle', function(event){

			event.preventDefault();

			if ( $('#navbar').is(':visible') ) {
				$(this).removeClass('active');
			} else {
				$(this).addClass('active');	
			}

			
			
		});

	};


	var goToTop = function() {
		
		var scrlTop = $(this).scrollTop(),
		goTop = $('.gotop');

		goTop.on('click', function(event){

			event.preventDefault();

			$('html, body').animate({
				scrollTop: $('html').offset().top
			}, 600);

			return false;
		});

		$(window).scroll(function(event){
			var scrlTop = $(this).scrollTop();

			if ( scrlTop > 600) {
				goTop.show().addClass('animated slideInUp');
			} else {
				goTop.removeClass('animated slideInUp slideOutDown').hide();			
			}
		});
		

	};


	// Page Nav
	var clickMenu = function() {

		$('#navbar a:not([class="external"])').click(function(event){
			var section = $(this).data('nav-section'),
			navbar = $('#navbar');

			if ( $('[data-section="' + section + '"]').length ) {
				$('html, body').animate({
					scrollTop: $('[data-section="' + section + '"]').offset().top
				}, 500);
			}

			if ( navbar.is(':visible')) {
				navbar.removeClass('in');
				navbar.attr('aria-expanded', 'false');
				$('.js-nav-toggle').removeClass('active');
			}

			event.preventDefault();
			return false;
		});


	};

	// Reflect scrolling in navigation
	var navActive = function(section) {

		var $el = $('#navbar > ul');
		$el.find('li').removeClass('active');
		$el.each(function(){
			$(this).find('a[data-nav-section="'+section+'"]').closest('li').addClass('active');
		});

	};

	var navigationSection = function() {

		var $section = $('section[data-section]');
		
		$section.waypoint(function(direction) {

			if (direction === 'down') {
				navActive($(this.element).data('section'));
			}
		}, {
			offset: '150px'
		});

		$section.waypoint(function(direction) {
			if (direction === 'up') {
				navActive($(this.element).data('section'));
			}
		}, {
			offset: function() { return -$(this.element).height() + 155; }
		});

	};


	


	// Window Scroll
	var windowScroll = function() {
		var lastScrollTop = 0;

		$(window).scroll(function(event){

			var header = $('#header'),
			scrlTop = $(this).scrollTop(),
			goTop = $('.gotop');

				//console.log(scrlTop);

				if ( scrlTop > 375 && scrlTop <= 2000 ) {
					header.addClass('navbar-fixed-top animated slideInDown');
					goTop.show().addClass('animated slideInUp');
				} else if ( scrlTop <= 500) {
					if ( header.hasClass('navbar-fixed-top') ) {
						header.addClass('navbar-fixed-top animated slideOutUp');
						goTop.show().addClass('animated slideOutDown');
						setTimeout(function(){
							header.removeClass('navbar-fixed-top animated slideInDown slideOutUp');
							goTop.removeClass('animated slideInUp slideOutDown').hide();
						}, 100 );
					}
				//goTop.hide();
			} 
			
		});
	};




	// Animations

	var contentWayPoint = function() {
		var i = 0;
		$('.animate-box').waypoint( function( direction ) {

			if( direction === 'down' && !$(this.element).hasClass('animated') ) {
				
				i++;

				$(this.element).addClass('item-animate');
				setTimeout(function(){

					$('body .animate-box.item-animate').each(function(k){
						var el = $(this);
						setTimeout( function () {
							el.addClass('fadeInUp animated');
							el.removeClass('item-animate');
						},  k * 200, 'easeInOutExpo' );
					});
					
				}, 100);
				
			}

		} , { offset: '85%' } );
	};
	


	
	

	// Document on load.
	$(function(){

		//parallax();

		// holdClick();

		// burgerMenu();

		// clickMenu();

		//windowScroll();

		// navigationSection();

		// goToTop();

		// Animations
		// contentWayPoint();

		// lightboxShow();

		// navScrollUp();
		

	});




	jQuery(document).ready(function($){
	//if you change this breakpoint in the style.css file (or _layout.scss if you use SASS), don't forget to update this value as well
	var MQL = 1170;

	//primary navigation slide-in effect
	if($(window).width() > MQL) {
		var headerHeight = $('.cd-header').height();
		$(window).on('scroll',
		{
			previousTop: 0
		}, 
		function () {
			var currentTop = $(window).scrollTop();
		    //check if user is scrolling up
		    if (currentTop < this.previousTop ) {
		    	//if scrolling up...
		    	if (currentTop > 0 && $('.cd-header').hasClass('is-fixed')) {
		    		$('.cd-header').addClass('is-visible');
		    	} else {
		    		$('.cd-header').removeClass('is-visible is-fixed');
		    	}
		    } else {
		    	//if scrolling down...
		    	$('.cd-header').removeClass('is-visible');
		    	if( currentTop > headerHeight && !$('.cd-header').hasClass('is-fixed')) $('.cd-header').addClass('is-fixed');
		    }
		    this.previousTop = currentTop;
		  });
	}

	//open/close primary navigation
	$('.cd-primary-nav-trigger').on('click', function(){
		$('.cd-menu-icon').toggleClass('is-clicked'); 
		$('.cd-header').toggleClass('menu-is-open');
		
		//in firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
		if( $('.cd-primary-nav').hasClass('is-visible') ) {
			$('.cd-primary-nav').removeClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
				$('body').removeClass('overflow-hidden');
			});
		} else {
			$('.cd-primary-nav').addClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
				$('body').addClass('overflow-hidden');
			});	
		}
	});

	$(function(){
		var hash = window.location.hash;
		hash && $('ul.nav a[href="' + hash + '"]').tab('show');

		$('.nav-tabs a').click(function (e) {
			$(this).tab('show');
			var scrollmem = $('body').scrollTop() || $('html').scrollTop();
			window.location.hash = this.hash;
			$('html,body').scrollTop(scrollmem);
		});

	});

	$('.grid').masonry({
	  // options...
	  itemSelector: '.grid-item',
	  columnWidth: 410,
	  gutter: 15
	});


});


}());
},{}],5:[function(require,module,exports){
'use strict';

var mediaquery  = require('../lib/media-query')();

var $navSearch = $('.nav-search');
var $navSearchForm = $navSearch.find('form');
var $navSearchInput = $navSearchForm.find('input');

$navSearchInput.val('');

// Search Toggle

$navSearchForm.find('button').on('click', function (e) {
    e.preventDefault();
    
	if( $navSearchInput.val().length ) {
		//$navSearchInput.val($navSearchInput.val().toLowerCase());
		$navSearchForm.submit();
		console.log("alert form");
	}
	else {	
		$navSearchInput.focus();
		$navSearch.toggleClass('open');
		$navSearchInput.val('');
	}
});

},{"../lib/media-query":13}],6:[function(require,module,exports){
'use strict';

$('[data-toggle="owl-carousel"]').each(function () {
	var $that = $(this);
	$that.owlCarousel($that.data());
});
},{}],7:[function(require,module,exports){
'use strict';

var $scrollUp = $('#scroll-up');
var $htmlbody = $('html, body');

function onscroll () {
  var $section = $('[data-anchor="'+location.hash+'"]');

  if($section.length) {
    $htmlbody.animate({
      scrollTop: $section.offset().top
    }, 700);
  }
}

$(window).on('hashchange load', onscroll).on('scroll', function() {
  if ($(this).scrollTop() > 100) {
    $scrollUp.fadeIn();
  } else {
    $scrollUp.fadeOut();
  }
});

$scrollUp.on('click',function (e) {
  e.preventDefault();
  $htmlbody.animate({
    scrollTop: 0
  }, 600);
});

$('[data-link="anchor"]').each(function () {
  var $this = $(this);
  var $anchor = $this.attr("href");

  $this.on('click', function (e) {

    var $anchor = $this.attr("href");
    var $section = $('[data-anchor="'+$anchor+'"]');

    $htmlbody.animate({
      scrollTop: $section.offset().top
    }, 600);

  });

});

$(window).on('load', function () {
  //var s = skrollr.init();
});
},{}],8:[function(require,module,exports){
jQuery(document).ready(function($){

	var timelineBlocks = $('.timeline-block'),
		offset = 0.8;

	//hide timeline blocks which are outside the viewport
	hideBlocks(timelineBlocks, offset);

	//on scolling, show/animate timeline blocks when enter the viewport
	$(window).on('scroll', function(){
		(!window.requestAnimationFrame) 
			? setTimeout(function(){ showBlocks(timelineBlocks, offset); }, 100)
			: window.requestAnimationFrame(function(){ showBlocks(timelineBlocks, offset); });
	});

	function hideBlocks(blocks, offset) {
		blocks.each(function(){
			( $(this).offset().top > $(window).scrollTop()+$(window).height()*offset ) && $(this).find('.timeline-img, .timeline-content').addClass('is-hidden');
		});
	}

	function showBlocks(blocks, offset) {
		blocks.each(function(){
			( $(this).offset().top <= $(window).scrollTop()+$(window).height()*offset && $(this).find('.timeline-img').hasClass('is-hidden') ) && $(this).find('.timeline-img, .timeline-content').removeClass('is-hidden').addClass('bounce-in');
		});
	}
});
},{}],9:[function(require,module,exports){
module.exports = function () {

	// if(location.hash !== undefined) {
	// 	var magnificAnchor = $('a[href="'+location.hash+'"]');

	// 	if(magnificAnchor.length) {
	// 		magnificAnchor.magnificPopup('open');
	// 	}
	// }

	// mfpOpen
	// $('[data-toggle="magnific-popup"]').each(function () {

	// });

};
},{}],10:[function(require,module,exports){
'use strict';

var sections = {};

sections.p9op4iy5p9ui017 = function (argument) {
	var $section = $('#section-p9op4iy5p9ui017');
	var $circles = $section.find('.circle');

	function resize () {
		$circles.each(function () {
			var $that = $(this);

			$that.height($that.width());
		});
	}

	function showContent() {
		$circles.each(function () {
			var $that = $(this);
			var $ctitle = $that.find('h3');
			var $ccontent = $that.find('.cirlce-content');

			$that.css('cursor','pointer');

			$that.click(function() {
				$ccontent.fadeIn(200).css('marginTop','30px');//addClass('show').removeClass('hidden');
				$ctitle.css('display','none');//.removeClass('show').addClass('hidden');
				event.stopPropagation();
			});
		});
	}

	resize();
	$(window).on('resize', resize);

	//showContent();
};

sections.f32tin4vzfptamg = function () {
	var $section = $('#section-f32tin4vzfptamg');
	var $carousel = $section.find('.owl-carousel');
	var $carouselIcons = $('<div class="owl-icons"></div>');
	var $carouselNav;
	var $carouselDots;
	var carouselIcon = '';

	function resize() {
		$carouselNav.width($carouselDots.outerWidth() + 110);
	}

	$carousel.owlCarousel({
 		animateIn: 'fadeIn',
  		items: 1,
  		loop: true,
  		nav: true,
  		navText: ['<i class="fa fa-caret-left"></i>','<i class="fa fa-caret-right"></i>'],
  		mouseDrag: true,
  		pullDrag: true,
  		touchDrag: true,
  		onDragged: function(elm){
  		    var icon = $(".owl-item.active").find('> div').data('icon');
  		    $carouselIcons.find('> .active').removeClass('active');
  		    $("." + icon).addClass('active');
  		}
	});

	// $.each($carousel.data('owl.carousel')._items, function () {
	//     var icon = $(this).find('> div').data('icon');
	// 	carouselIcon += '<div class="owl-icon '+ icon +'"><i class="emicon em'+ icon +'"></i></div>';
	// });

	$carousel.find('.owl-stage-outer').before($carouselIcons.append(carouselIcon));
	
	$carouselIcons.find('> .owl-icon:first-child').addClass('active');

	$carouselIcons.on('click', '.owl-icon', function () {
		var $that = $(this);
		$carouselIcons.find('> .active').removeClass('active');
		$that.addClass('active');
		$carousel.trigger('to.owl.carousel',[$that.index()]);
	});

	$("#section-f32tin4vzfptamg .owl-nav").on('click', '.owl-prev, .owl-next', function(){
	    var icon = $(".owl-item.active").find('> div').data('icon');
  	    $carouselIcons.find('> .active').removeClass('active');
  	    $("." + icon).addClass('active');
	});
	
	$carouselNav = $carousel.find('.owl-nav');
	$carouselDots = $carousel.find('.owl-dots');
	
	resize();
	$(window).on('resize',resize);
};

module.exports = function() {
	$.each(sections, function() {
		this();
	});
};
},{}],11:[function(require,module,exports){
'use strict';

},{}],12:[function(require,module,exports){
'use strict';

var mediaquery  = require('./lib/media-query');

var controllers = {'about-us': require('./controllers/about-us.js'),'home': require('./controllers/home.js'),'symptoms': require('./controllers/symptoms.js')};

require('./common/form.js');require('./common/googlemap.js');require('./common/magnific-pop.js');require('./common/main.js');require('./common/navbar.js');require('./common/owl-carousel.js');require('./common/scroll.js');require('./common/timeline.js');

// init media-query
mediaquery();

$('[data-controller]').each(function(){
    var that = $(this);
    var data = that.data();
    var controller = data.controller;

    delete data.controller;

    if( typeof controllers[ controller ] === 'function' ) {
        controllers[ controller ](data);
    }
}); 

$(function() {
    // add class when document is ready
    $('body').addClass('document-ready'); 
});

// add class when window is loaded
$(window).on('load', function(){
    $('body').addClass('window-loaded');
});
},{"./common/form.js":1,"./common/googlemap.js":2,"./common/magnific-pop.js":3,"./common/main.js":4,"./common/navbar.js":5,"./common/owl-carousel.js":6,"./common/scroll.js":7,"./common/timeline.js":8,"./controllers/about-us.js":9,"./controllers/home.js":10,"./controllers/symptoms.js":11,"./lib/media-query":13}],13:[function(require,module,exports){
'use strict';

// media breakpoint
var instance;

function MediaQuery (_breakpoints) {

	var that = this;

	// default breakppoints
	that.breakpoints = {
		xs : 0,
		sm : 0,
		md : 0,
		lg : 0
	};

	that.breakpoints = $.extend({},that.breakpoints,_breakpoints || {});

	var $mediaquery = $('<div class="media-query"></div>');

	$('body').append( $mediaquery );

	$.each(that.breakpoints,function(index){

		var $div = $('<div class="mq-'+index+'"></div>');

		$mediaquery.append($div); 

		that.set_breakpoint_size(index,$div.outerWidth());

	});
}

MediaQuery.prototype.set_breakpoint_size = function (breakpoint,width) { 
	this.breakpoints[breakpoint] = width;
};

MediaQuery.prototype.get_breakpoint_size = function (breakpoint) { 
	return this.breakpoints[breakpoint];
};

MediaQuery.prototype.breakpoint = function (breakpoint,range) { 
	var that = this, flag = false, screen_width = $(window).outerWidth();

	if( $.isArray(breakpoint) )
	{
		flag =  screen_width >= that.get_breakpoint_size(breakpoint[0]) && screen_width <= (that.get_breakpoint_size(breakpoint[1]) - 1);
	}
	else
	{
		if( range === 'min')
			flag =  screen_width >= that.get_breakpoint_size(breakpoint);
		else if( range === 'max')
			flag = screen_width <= (that.get_breakpoint_size(breakpoint) - 1);
	}

	return flag;
};

module.exports = function (options) {
	if ( !instance ) instance = new MediaQuery(options);

	return instance;
};
},{}]},{},[12]);
